<template>
    <div>
        <div class="s-user-info flex" v-if="isReady">
            <!--        展示状态-->
            <div class="u-base flex flex-direction align-center" v-if="!isEdit">
                <div class="u-info-avatar">
                    <img v-if="userInfo.heardImg"
                         style="border-radius:50%; margin-right: 30px; object-fit: cover; object-position: center;"
                         :src="userInfo.heardImg" alt="头像" title="头像">

                        <img
                                v-else
                             style="border-radius:50%; margin-right: 30px; object-fit: cover; object-position: center;"
                             src="../../../assets/img/company/avata.png" alt="头像" title="头像">

                    <div class="edit-message" @click="changeEditForm">
                        <i class="el-icon-edit"></i><span>编辑</span>
                    </div>
                </div>
                <div class="u-info-desc"><span>{{userInfo.userName}}</span>
                    <img v-if="userInfo.gender===2" src="../../../assets/img/users/women.png" alt="">
                    <img v-if="userInfo.gender===1" src="../../../assets/img/users/man.png" alt="">
                </div>
                <div class="view_position">{{userInfo.province}} {{userInfo.city}} {{userInfo.area}}</div>
                            <div class="view_introduce" v-if="userInfo.idNumber">已实名，可投递简历</div>
            </div>

            <!--        编辑状态-->
            <div class="u-base-edit flex flex-direction align-center" v-else>
                <img v-if="userInfo.heardImg"
                     style="width:100px;height:100px;border-radius:50%; margin-right: 30px; object-fit: cover; object-position: center;"
                     :src="userInfo.heardImg || '../../../assets/img/company/avatar.png'" alt="">
<!--                <div v-else class="edit-avatar flex">-->
<!--                </div>-->
                <update-avatar v-else  @uploadAvatarSuccess="avatarSuccess"></update-avatar>
                <div class="edit-item">
                    <span class="title must">姓名</span>
                    <el-input placeholder="请输入姓名" size="small" :disabled="userInfo.idNumber==''?false:'disabled'" v-model="userInfo.userName"></el-input>
                </div>

                <div v-if="isRealName" class="edit-item">
                    <span class="title must">身份证号</span>
                    <el-input placeholder="请输入身份证号" disabled maxLength="18" size="small"
                              :value="userInfo.idNumber"></el-input>
                </div>

                <div v-else class="edit-item">
                    <span class="title ">身份证号</span>
                    <el-input placeholder="实名认证后自动获取" disabled maxLength="18" size="small" v-model="userInfo.idNumber"></el-input>
                </div>
                <div class="edit-item">
                    <span class="title">性别</span>
                    <el-select v-model="userInfo.gender" placeholder="实名认证后自动生成">
                        <el-option
                                v-for="item in genderList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="true"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="edit-item">
                    <span class="title">出生日期</span>
                    <el-date-picker
                            v-model="userInfo.birthday"
                            type="date"
                            :disabled="true"
                            placeholder="实名认证后自动生成">
                    </el-date-picker>
                </div>
                <div class="edit-item">
                    <span class="title">籍贯地</span>
                    <!--                    <area-linkage :level="2" v-model="areaSelected"></area-linkage>-->
                    <area-cascader v-model="areaSelected" type="text" :level="1" :data="pcaa"></area-cascader>
                </div>
                <div class="edit-item">
                    <span class="title">邮箱</span>
                    <el-input placeholder="请填写邮箱" v-model="userInfo.email" size="small"></el-input>
                </div>
                <div class="edit-item">
                    <span class="title">微信号码</span>
                    <el-input placeholder="请填写微信号" maxLength="21" v-model="userInfo.vxNumber" size="small"></el-input>
                </div>
                <div class="tool-bar" @click="saveUserInfo">保 存</div>
                <!-- <div class="tool-tips">* 此信息用于站内言职社区功能，不会同步修改简历</div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import userRequest from "@/api/user";
    import areaData from 'area-data'; // v5 or higher
    import updateAvatar from "@/components/updateAvatar/updateAvatar";
    export default {
        components: {
            updateAvatar
        },
        mounted() {
            this.getUserInfo()
        },
        watch: {},
        data() {
            return {
                pcaa: areaData.pcaa,
                hasLogin: false,  // 是否已经登录,是否已经有基本的用户信息
                userInfo: null,
                isEdit: false,
                radio: 2,
                isReady: false,
                isRealName: false, //  是否已经实名
                headerImgArr:[],
                genderList: [
                    {
                        label: "实名认证后自动生成",
                        value: 0
                    },
                    {
                        label: "男",
                        value: 1
                    }, {
                        label: "女",
                        value: 2

                    }
                ],
                areaSelected: ['江西省', '广信区', '信州区']
            }
        },
        methods: {
            getUserInfo2(){
                userRequest.getUserInfo({}).then(ress=>{
                        if(ress.code==200){
                            console.log(ress)
                            window.localStorage.setItem('userInfo', JSON.stringify(ress.data))
                             this.userInfo=ress.data
                        }
                    })
            },
            getUserInfo() {
                let hasLogin = window.localStorage.getItem("hasLogin")  // 登录标记
                if (hasLogin === 'true') {
                    userRequest.getUserInfo().then(res => {
                        console.log("获取到的用户的基本信息", res)
                        this.userInfo = res.data
                        this.areaSelected[0] = this.userInfo.province
                        this.areaSelected[1] = this.userInfo.city
                        this.areaSelected[2] = this.userInfo.area
                        this.isReady = true
                        this.headerImgArr.push(this.userInfo.heardImg)
                        if (res.data.idNumber) {  // 有身份证号，表示已经实名认证
                            this.$emit('isRealName', true)
                            this.isRealName = true
                        }
                    })
                } else {
                    this.$message.error('未登录，获取个人信息失败')
                }

            },
            avatarSuccess(res){
                if(res.code === 200){
                    this.userInfo.heardImg  = res.data.message
                }
            },
            changeEditForm() {
                console.log(11)
                this.isEdit = true
            },
            saveUserInfo() {

                /**
                 * 首先进行实名认证，然后在上传更改用户信息
                 */
                this.requestUserInfo()
                // if (this.isRealName) {
                //     this.requestUserInfo()
                // } else {
                //     userRequest.setRealName({idNumber:this.userInfo.idNumber}).then(res=>{
                //         if(res.code === 200){  // 实名认证成功
                //             this.requestUserInfo()
                //         }else{
                //             this.$message.error("实名认证失败，请稍后重试")
                //             return;
                //         }
                //     })
                // }


            },
            requestUserInfo() {  // 实名成功以后，配置个人信息 (邮箱等)
                this.userInfo.province = this.areaSelected[0]
                this.userInfo.city = this.areaSelected[1]
                this.userInfo.area = this.areaSelected[2]
                userRequest.setUserInfo(this.userInfo).then(res => {
                    if (res.code === 200) {
                        this.$message.success("保存成功~")
                        this.isEdit = false
                        // this.$emit("refreshUserInfo",true)
                        this.getUserInfo2()
                    }
                })
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    .head-img-input{
        color: transparent;
        opacity: 0;
    }

    .head-img-box{  /* 上传图片的背景图盒子*/
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-size: 50px 50px;
        background: url("../../../assets/img/users/photo.png") center center no-repeat;
    }

    .s-user-info {
        min-width: 600px;
        flex-direction: column;
        /*margin-left: 0px;*/
        justify-content: center;
        align-items: center;
        /*margin-top: 40px;*/
        /*margin-bottom: 100px;*/
        padding-bottom: 50px;

        .u-info-avatar {
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 20px;

            img {
                width: 100px;
                height: 100px;
            }
        }

        .edit-message {
            cursor: pointer;
            position: absolute;
            user-select: none;
            right: -120px;
            top: 15px;
            color: $main;
            font-size: 16px;

            span {
                margin-left: 7px;
            }
        }
    }

    .u-info-desc {
        font-size: 18px;
        color: #333;
        text-align: center;

        img {
            position: relative;
            width: 18px;
            height: 18px;
            top: 3px;
            margin-left: 7px;
        }
    }

    .view_position {
        color: #666;
        margin-top: 10px;
    }

    .view_introduce {
        color: #666;
        margin-top: 10px;
    }

    .u-base-edit {
        width: 400px;
        margin-left: 0px;

        .edit-avatar {
            cursor: pointer;
            left: 0px;
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;

            /*&:hover {*/
            /*    .avatar-photo {*/
            /*        display: block;*/
            /*    }*/
            /*}*/

            .avatar-photo {
                position: absolute;
                display: block;
                width: 100px;
                height: 100px;
                top: 0;
                left: 0;
                background: rgba($color: #000000, $alpha: 0.3);

                img {
                    width: 40px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }

        .edit-item {
            min-width: 300px;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            /*justify-content: space-around;*/
            .title {
                min-width: calc(5em + 15px);
                height: 30px;
                line-height: 30px;
                margin-bottom: 0px;
            }

            .must {
                &::after {
                    content: "*";
                    color: red;
                    margin-right: 10px;
                }
            }
        }

        .tool-bar {
            margin-top: 20px;
            cursor: pointer;
            height: 46px;
            line-height: 46px;
            text-align: center;
            color: #fff;
            background: $main;
            min-width: 300px;

            &:hover {
                background: $hover;
            }
        }

        .tool-tips {
            color: #999;
            margin-top: 12px;
            text-align: center;
        }
    }
</style>
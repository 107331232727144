

<template>
    <div>
        <el-upload
                class="avatar-uploader"
                action="/api/qiniu/getImageUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
            <img v-if="currentImgUrl" :src="currentImgUrl" class="avatar">
            <i v-else class="el-icon-camera avatar-uploader-icon"></i>
        </el-upload>
    </div>
</template>


<script>
    export default {
        props:{
            imageUrl:{
                type:String,
                default:""
            }
        },
        data() {
            return {
                currentImgUrl:""   // 组件内操作的图片
            };
        },
        methods: {
            handleAvatarSuccess(res, file) {
                this.currentImgUrl = URL.createObjectURL(file.raw);
                this.$emit("uploadAvatarSuccess",res)
                console.log("上传图片成功",res,file)
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            }
        }
    }
</script>


<style>
    .avatar-uploader .el-upload {
        /*width: 100px;*/
        /*height: 100px;*/
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;

        line-height: 100px;
        text-align: center;
    }
    .avatar {
        /*width: auto;*/
        /*height: auto;*/
        width: 100px;
        height:100px;
        display: block;
    }
</style>

